@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";


button {
    font-weight: 900;
    font-size: 0.75rem;
    border: 0;
    padding-right: 0;
    display: block;
    background-color: transparent;

    @include respond-below(sm){
        flex:1
    }

    .button {
        font-weight: 900;
        font-size: 0.75rem;
        line-height: 0.938rem;
        padding: 0.8rem 1.875rem 0.8rem 1.875rem;
        display: block;
    }
    .buttonSmall {
        font-weight: 900;
        font-size: 0.75rem;
        line-height: 0.938rem;
        padding: 0.75rem 1rem;
        display: block;
    }

    .black {
        background-color: color('black');
        border: 0.063rem solid color('black');
        border-radius: 3.125rem;
        color: color('white');
        cursor: pointer;
    }

    .red {
        background-color: color('mandyRed');
        border: 0.063rem solid color('white');
        border-radius: 3.125rem;
        color: color('white');
        cursor: pointer;
    }

    .white {
        background-color: color('white');
        border: 0.063rem solid color('black');
        border-radius: 3.125rem;
        color: #000;
        cursor: pointer;
    }

    .disabled {
        background-color: #adadad;
        border: 0.063rem solid #adadad;
        cursor: not-allowed;
    }
}

.underLGFlex{
    @include respond-below(lg){
        flex:1
    }
}
