@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";


.container {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    border-radius: 100px;
    background-color: color('black');
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem 3rem;
    z-index: 2;
}

.containerMobile {
    width: 95%;
    transform: translate(-50%, 0%);
}

.content {
    display: flex;
    align-items: center;
}

.divImage {
    flex: 1;
    padding-right: 1rem;
}

.img {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    min-width: 100px;
    min-height: 60px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;

}

.contentDescription {
    display: flex;
    flex-direction: column;
}

.contentDescriptionMobile {
    align-items: center;
}

.label {
    word-break: keep-all;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    padding-bottom: 0.3rem;
}

.messageError {
    display: flex;
    align-items: flex-start;
    color: color('warningYellow');
    font-size: 0.8rem;
    width: 100%;
    margin: 0.1rem 0 0 0.5rem;
}