@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.photoDiv {
    width: 100%;
    padding-top: 2rem;

    @include respond-below(sm) {
        width: 50%;
        padding-top: 1rem;
        margin: auto;
    }
    
    @include respond-below(xs) {
        width: 100%;
    }
}

.buttonsDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .saveButtons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        @include respond-below(sm) {
            justify-content: space-between;
            margin-bottom: 1rem;
        }
    }

    .tabButtons{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        @include respond-below(sm) {
            justify-content: space-between;
            margin-bottom: 1rem;
        }
    }
}

.requiredError {
    color: color("warningYellow");
    font-size: 0.8rem;
}


.center{
    display: flex;
    align-items: center;
}

.translationsTree {
    padding: 0.6rem;
}
