@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.label {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 0.75rem;
    text-align: left;
    padding-right: 1rem;
}

.requiredField{
    color: color('warningYellow');
}