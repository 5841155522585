@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.photoDiv {
    padding-top: 1.9rem;

    @include respond-below(sm) {
        width: 50%;
        padding-top: 1rem;
        margin: auto;
    }
    
    @include respond-below(xs) {
        width: 100%;
    }
}

.hideTab{
    display: none;
}

