@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.upperDiv {
    margin: 3rem 0 1rem 0;
    width: 100%;
    background-color: color("wildSand");
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.75rem;


    padding: 0 8.4rem 0 8.4rem;

    @include respond-below(md) {
        padding: 0 1rem 0 1rem;
        margin-top: 1.5rem;
    }

     @include respond-below-portrait(lg){
        padding: 0 1rem 0rem 1rem;
    }

    @include respond-below(xs) {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        margin-top: 0;
    }

    .upperBurgerIcon {
        @include respond-above(xs) {
            display: none;
        }
    }

    .logoDiv {
        display: flex;
    }
}

.logoContainer {
    width: 9.375rem;
    height: 1.875rem;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-below(xs) {
        margin-left: 1rem;
    }
}


.webSearchBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;


    .webInput {
        width: 80%;
        border: 1px solid color('mercury');
        border-radius: 3.125rem;
        display: flex;
        align-items: center;
        background-color: color('white');

        @include respond-below(sm) {
            width: 100%;
        }

        input {
            border: none;
            width: 100%;
            height: 100%;
            margin-left: 1rem;
            text-overflow: ellipsis;
            overflow: hidden;

            &:focus {
                outline: none;
            }
        }
    }


    @include respond-below(xs) {
        display: none;
    }
}

.resultsDiv {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;

    width: 80%;
    top: 3.6rem;
    background-color: color('white');

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.625rem;

    :hover {
        background-color: darken(color('white'), 3.5);
        border-radius: 0.625rem;
    }

    @include respond-below(xs) {
        width: 100%;
        top: 5.313rem;
    }

}

.resultRow {
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    cursor: pointer;

    .resultIcon {
        height: 2rem;
        width: 2rem;
        border-radius: 2rem;

        rect {
            fill: color('wildSand')
        }
    }

    .resultData {
        display: flex;
        flex: 1;
        flex-direction: column;
        word-break: break-all;
        margin: 0.3rem 0 0.3rem 0.625rem;


        .title {
            font-weight: 900;
            font-size: 1rem;
            line-height: 1.125rem;
            color: color('black')
        }

        .subtitle {
            font-size: 0.8rem;
            color: color('breadCrumbs')
        }
    }
}

.chosenUserMobile {
    display: flex;
    align-items: center;
    word-break: break-all;
    background-color: color('white');
    margin: 0 1rem 1rem 1rem;
    border-radius: 2.5rem;

    .resultIconMobile {
        height: 2.7rem;
        margin-left: 0.625rem;
        padding: 0.2rem 0;
        border-radius: 2rem;

        rect {
            fill: color('wildSand')
        }

    }

    .chosenData {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0 0.5rem 0.625rem;
        flex: 1;

        .title {
            font-weight: 900;
            font-size: 1rem;
            line-height: 1.125rem;
            color: color('black');
        }

        .subtitle {
            font-size: 0.8rem;
            color: color('breadCrumbs')
        }
    }
}

.closeIcon {
    height: 1.5rem;
    width: 1.5rem;

    @include respond-below(xs) {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.625rem;
    }
}

.resultIconSearchBar {
    height: 2rem;
    width: 2rem;
    margin: 0.625rem 0 0.625rem 1.25rem;

    rect {
        fill: color('wildSand')
    }

}

.mobileSearchBar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    input {
        border: none;
        width: 100%;
        height: 100%;
        margin-left: 1rem;

        &:focus {
            outline: none;
        }
    }
}

.iconsDiv {
    display: flex;
    position: relative;


    .circularIcon {
        width: 2.75rem;
        height: 2.75rem;
        margin-left: 1rem;
        cursor: pointer;
        border-radius: 2rem;

        @include respond-below(xs) {

            rect,
            circle {
                fill: color('wildSand')
            }

            margin-left: 0.2rem;

            width: 2.4rem;
            height: 2.4rem;
        }
    }

    .opacity {
        opacity: 0.25;
    }

    .selectedProfileIcon {
        rect {
            fill: black;
        }

        path {
            stroke: white;
        }
    }

    .text {
        padding: 0 0.063rem 0 0.2rem
    }
}


.onlyXS {
    @include respond-above(xs) {
        display: none;
    }
}

.profileDiv {
    display: flex;
    flex-direction: column;
    background-color: color('optionsMenus');
    width: 18rem;
    justify-content: center;
    padding: 1.25rem;
    border-radius: 0.625rem;
    position: absolute;
    right: 0.0625rem;
    top: 3rem;
    z-index: 2;


    .info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 1.2rem;
    }

    .marginLeft {
        margin-left: 0.625rem;
    }

    .button {
        margin-top: 1.25rem;
    }

    .name {
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.125rem;
        color: #000000;
    }

    .email {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: #000000;
    }
}




.breadCrumbsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8.4rem;

    @include respond-below(md) {
        padding: 0 1rem 0 1rem;
    }

    @include respond-below-portrait(lg){
        padding: 0 1rem 0rem 1rem;
    }

    .breadCrumbsDiv {
        margin-bottom: 1.5rem;

        ul {
            margin: 0 0 0 1rem;
            padding: 0;
            align-items: flex-end;
            list-style-type: none;

            @include respond-below(xs) {
                line-height: 1.25rem;
                margin-left: 0rem;
            }

            a::after {
                content: "•";
                margin: 0 1.25rem;
            }

            li {
                color: color("breadCrumbs");
                position: relative;
                display: inline-block;

                a {
                    display: flex;
                    text-decoration: none;
                    font-weight: 900;
                    font-size: 0.9rem;
                    line-height: 0.938rem;
                    color: color("breadCrumbs");
                    justify-content: flex-start;
                    word-break: break-all;


                    @include respond-below(xs) {
                        line-height: 1.25rem;
                        margin-left: 0rem;
                    }
                }
            }

            li:last-of-type {
                a::after {
                    display: none;
                }
            }
        }

        .lowerBurgerIcon {
            cursor: pointer;

            @include respond-below(xs) {
                display: none;
            }
        }
    }

}