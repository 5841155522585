@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.inputContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1rem;
    background: #fff;
    border-radius: 20px;
    width: 30rem;

    @include respond-below(lg) {
        width: 20rem;
    }

    .inputContent {
        background: #fff;
        border: unset;
        width: 100%;

        &:focus{
            outline: none;
        }
    }

    .inputImage {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
    }
}

.pointer {
    cursor: pointer;
}
