@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.box {
    min-height: 2.5rem;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #666;
}

.input {
    width: 100%;
    background: #ffffff;
    margin: 0.6rem 0;
    font-size: 1rem;
    resize: none;
    font-family: 'Inter', sans-serif;
    border: none;
    outline: none;
}

.noWarning {
    border: 1px solid color('mercury');

    &:focus {
        outline: none;
        border-color: color('frenchGray');
    }
}

.warning {
    border: 1px solid color('warningYellow');

    &:focus {
        outline: none;
        border-color: color('warningYellow');
    }
}

.disabled {
    background-color: color('whisper');
    opacity: 1;
}

.invalidEmailMessage {
    font-size: 0.8rem;
    color: color('warningYellow');
}