
.container {
    min-width: 7rem;
    line-height: 1.2;
    text-align: left;
}

.title {
    font-size: 1rem;
    font-weight: bold;
    text-overflow: ellipsis;
}

.subtitle {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
    text-overflow: ellipsis;
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fromText {
    font-size: 0.8rem;
    margin-right: 1rem;
    white-space: nowrap;
    flex: 1;
}

.price {
    font-weight: bold;
    white-space: nowrap;
}
