@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.table {
    width: 100%;
    border-collapse: collapse;
    
    thead {
        th {
            text-align: start;
            font-size: 0.7rem;
            font-weight: 900;
            color: color("tableHeaders");
            padding-bottom: 0.625rem;
            padding-left: 1rem;
            
            a{
                margin-right: 0.625rem;
            }
        }
    }

    tbody {
        background-color: #fff;
        width: 100%;
        
        td {
            font-size: 0.75rem;
            font-weight: 400;
            color: #000;
            padding: 1rem 0 1rem 1rem;
            border-bottom: 0.063rem solid #e6e6e6;
            word-break: break-all;


            @include respond-below(sm){
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }

        tr:last-child td {
            border-bottom: none;
        }

        tr:first-child td:first-child {
            border-top-left-radius: 0.625rem;
        }

        tr:first-child td:last-child {
            border-top-right-radius: 0.625rem;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 0.625rem;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 0.625rem;
        }
    }
}

.noItemsText {
    padding: 0.5rem;
    text-align: center;
}


.image {
    height: 2.188rem;
    width: 3.75rem;
    background: #d9d9d9;
}

.optionsIcon {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}

.tableDiv {
    position: relative;
}

.iconDiv {
    position: absolute;
    top: -0.2rem;
    right: 5px;
    cursor: pointer;
}

.sm {
    @include respond-below(sm) {
        display: none !important;
    }
}

.md {
    @include respond-below(md) {
        display: none !important;
    }
}

.lg {
    @include respond-below(lg) {
        display: none !important;
    }
}

.xl {
    @include respond-below(xl) {
        display: none !important;
    }
}

.xxl {
    @include respond-below(xxl) {
        display: none !important;
    }
}
