@mixin containerWrapper {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    // @media (min-width: 640px) {
    //     max-width: 640px;
    // }

    // @media (min-width: 768px) {
    //     max-width: 768px;
    // }

    // @media (min-width: 1024px) {
    //     max-width: 1024px;
    // }

    // @media (min-width: 1280px) {
    //     max-width: 1280px;
    // }

    // @media (min-width: 1536px) {
    //     max-width: 1536px;
    // }
    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1536px) {
        max-width: 1476px;
    }
}

@mixin fontWeight($weight) {
    $weights: (
        thin: 100,
        extra-light: 200,
        ultra-light: 200,
        light: 300,
        normal: 400,
        book: 400,
        regular: 400,
        medium: 500,
        semi-bold: 600,
        demi-bold: 600,
        bold: 700,
        extra-bold: 800,
        ultra-bold: 900,
        heavy: 900,
        black: 900,
        ultra: 900,
        ultra-black: 900,
        extra-ultra: 900,
    );

    $output: $weight;

    @if map-has-key($weights, $weight) {
        $output: map-get($weights, $weight);
    }

    font-weight: $output;
}

@mixin placeholderColor($color) {
    &.placeholder {
        color: $color;
    }

    &:-moz-placeholder {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }
}

@mixin scrollbars() {
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
        background: color("black");
    }

    &::-webkit-scrollbar-track {
        background: color("white");
    }

    //* For Firefox *
    scrollbar-width: thin;
    scrollbar-color: color("black") color("white"); 
}

// Elevation

$key-shadow-umbra-map: (
    1: 0 0 2px 0,
    2: 0 0 4px 0,
    3: 0 1px 6px 0,
    4: 0 2px 4px 0,
    5: 0 4px 6px 0,
    6: 0 6px 10px 0,
    7: 0 6px 10px 0,
    8: 0 8px 10px 1px,
    9: 0 9px 12px 2px,
    10: 0 10px 13px 2px,
    11: 0 11px 14px 2px,
    12: 0 12px 16px 2px,
    13: 0 13px 18px 2px,
    14: 0 14px 21px 2px,
    15: 0 15px 22px 2px,
    16: 0 16px 23px 2px,
    17: 0 17px 24px 2px,
    18: 0 18px 25px 3px,
    19: 0 19px 26px 3px,
    20: 0 20px 28px 3px,
    21: 0 21px 30px 3px,
    22: 0 22px 32px 3px,
    23: 0 23px 36px 3px,
    24: 0 24px 38px 3px,
);

$key-shadow-penumbra-map: (
    1: 0 2px 2px 0,
    2: 0 3px 4px 0,
    3: 0 3px 4px 0,
    4: 0 4px 5px 0,
    5: 0 4px 5px 0,
    6: 0 1px 18px 0,
    7: 0 2px 18px 1px,
    8: 0 3px 14px 2px,
    9: 0 3px 18px 3px,
    10: 0 4px 16px 3px,
    11: 0 4px 18px 3px,
    12: 0 5px 22px 4px,
    13: 0 5px 26px 4px,
    14: 0 5px 29px 4px,
    15: 0 6px 34px 5px,
    16: 0 6px 30px 5px,
    17: 0 6px 36px 5px,
    18: 0 7px 33px 6px,
    19: 0 7px 37px 6px,
    20: 0 8px 34px 6px,
    21: 0 8px 39px 7px,
    22: 0 9px 38px 7px,
    23: 0 9px 40px 7px,
    24: 0 9px 46px 8px,
);

$ambient-shadow-map: (
    1: 0 1px 3px 0,
    2: 0 1px 5px 0,
    3: 0 1px 7px 0,
    4: 0 1px 8px 0,
    5: 0 1px 10px 0,
    6: 0 3px 5px 0,
    7: 0 3px 8px 0,
    8: 0 4px 15px 0,
    9: 0 5px 11px 0,
    10: 0 5px 14px 0,
    11: 0 6px 10px 0,
    12: 0 6px 13px 0,
    13: 0 7px 10px 0,
    14: 0 7px 12px 0,
    15: 0 7px 14px 0,
    16: 0 8px 13px 0,
    17: 0 8px 15px 0,
    18: 0 9px 11px 0,
    19: 0 9px 13px 0,
    20: 0 9px 15px 0,
    21: 0 10px 12px 0,
    22: 0 10px 15px 0,
    23: 0 11px 13px 0,
    24: 0 11px 15px 0,
);

// If you don't want all the classes can also just include this mixin and provide an elevation value in any class
@mixin elevation($elevation, $color: #000000) {
    $key-shadow-umbra-colour: rgba($color, 0.14);
    $key-shadow-penumbra-colour: rgba($color, 0.12);
    $ambient-shadow-colour: rgba($color, 0.2);

    box-shadow: map-get($key-shadow-umbra-map, $elevation) $key-shadow-umbra-colour, map-get($key-shadow-penumbra-map, $elevation) $key-shadow-penumbra-colour,
        map-get($ambient-shadow-map, $elevation) $ambient-shadow-colour;
}

// This creates the classes for each elevation dp with numbers from 1 to 24
@for $elevation from 1 through 24 {
    .elevation-#{$elevation} {
        @include elevation($elevation);
    }
}
