@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.side {
    flex: 1;
    border-right: 1px solid #e6e6e6;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;

    &:last-child {
        border-right: 0;
    }
}

.colorBox {
    width: 1rem;
    height: 1rem;
    margin-bottom: 0.4rem;
    border: 2px solid transparent;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &.colorActive {
        border: 2px solid black;
    }
}

.sizeBox {
    text-align: center;
    cursor: pointer;
    color: #c0c0c0;

    &.sizeActive {
        color: #000000;
    }
}