@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    border-radius: 4px;
    border: 1px solid black;
    padding: 0.6rem;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .header {
        font-weight: bold;
        cursor: pointer;
        flex: 1;
    }
}

.contentContainer {
    padding: 0.4rem;

    .selectorContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include respond-below(xs){
            flex-direction: column;
        }
    }

    .selectContainer {
        flex: 1;
        width: 100%;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include respond-below(xs){
           margin-top: 0.5rem;
        }
    }
}
