@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";


.titleContainer {
    margin-bottom: 17px;
}

.textTitle {
    font-size: 20px;
    font-weight: 'bold';
    @include fontWeight('bold');
    margin-bottom: 10px;
    color: color('primaryDark');
}

.messageContainer {
    margin-bottom: 20px;
}

.textMessage {
    font-size: 16px;
    color: 'blue';
}

.buttons_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

    // @include respond-below(sm){
    //     display: inline;
    //     justify-content: center;
    //     align-items: center;
    // }
}