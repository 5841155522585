@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.listDiv {
    .categorySlide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.625rem;
        height: 12.5rem;
        position: relative;
        cursor: pointer;
        color: #fff;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.188rem;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        
        
        span{
            word-break:break-all;
            padding:10px;
        }
       
        .editIcon {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
        }
    }
}

.span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.563rem;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #000;
    margin-bottom: 1.3rem;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.backIcon {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.5rem;
}

.justifyCenter {
    display: flex;
    align-items: center;
    cursor: pointer;
}