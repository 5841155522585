@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

$iconSize: 1.25rem;
$directionalIconSize: 2.5rem;

.buttonsContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include respond-below(sm) {
        flex-wrap: wrap;
    }
}

.title_favorite_grid{
    max-width: 100%;
    display: grid;
    grid-template-areas:
        "l r"
        "l r"
        "l r";
    grid-template-columns: 3fr 1fr;

    @include respond-below(sm){
        max-width: 101%;
        grid-template-columns: 1fr;
    }

    .inputContainer {
        grid-area: l;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .favoritesContainer {
        position: absolute;
        right: 0;
        top: -10px;
    }
}

.requiredError {
    color: color("warningYellow");
    font-size: 0.8rem;
}

.titleMarginTop {
    margin-top: 1rem;
}

.marginTop {
    margin-top: 1rem;
}

.hideTab {
    display: none;
}

.inputAlignRight {
    text-align: right;
}

.grid {
    max-width: 103%;
    display: grid;
    grid-template-areas:
        "l r"
        "l r"
        "l r";
    grid-template-columns: 3fr 1fr;
}


.buttonIconFavorite {
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin-right: 0.4rem;
    background: rgba(17, 17, 17, 0.2);

    &:last-child {
        margin-right: 0;
    }

    &.buttonIconEdit {
        padding: 0rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    @include respond-below(sm) {
        padding: 0.2rem;
        width: 2.4rem;
        height: 2.4rem;
    }
}

.myMasonryGrid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.myMasonryGridColumn {
    padding-left: 30px;
    background-clip: padding-box;
}
  
.myMasonryGridColumn > div {
    background: grey;
    margin-bottom: 30px;
}

.textImagesForPin {
    font-size: 20px;
    font-weight: bold;

    @include respond-below(sm) {
        font-size: 15px;
    }
    
    @include respond-below(md) {
        font-size: 15px;
    }
}

.imagesForPinArea {
    max-width: 103%;
    display: grid;
    grid-template-areas:
        "l r"
        "l r"
        "l r";
    grid-template-columns: 3fr 1fr;
}

.imagesForPinAreaMobile {
    max-width: 103%;
    grid-template-areas:
        "l r"
        "l r"
        "l r";
    grid-template-columns: 3fr 1fr;
}

.imagesForPinContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1rem;
    width: 100%;
}

.backIcon {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.5rem;
}


.pointer {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.space_between{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}