@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.searchContainer {
    padding: 0 0 15px 0;

    .search {
        display: flex;
        flex: 1;
        width: auto;
    }
}

.bodyContainer {
    background-color: color("white");
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
    padding: 0 0.8rem 0 0.8rem;
    border-radius: 0.625rem;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0.4rem;
    border-bottom: 1px solid color("gallery");

    .title {
        flex: 1;
        font-weight: bold;
    }
}

.itemsContainer {
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 0.4rem;
        border-bottom: 0.063rem solid #e6e6e6;
        cursor: pointer;

        &:last-child {
            border-bottom: 0;
        }

        .info {
            flex: 1;
            font-size: 0.75rem;
            font-weight: 400;
            color: #000;
        }

        .checkbox {
            margin-left: 0.6rem;
        }
    }
}
