@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.header {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
}

.body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.cropContainer {
    border-radius: 5px;
}

.resultContainer {
    padding: 0 1rem;
    width: 300px;
}

.resultCanvas {
    width: 100%;
    height: auto;
}

.circularResultCanvas{
    border-radius: 50%;
}

.divider {
    background-color: #f1f1f1;
    height: 1px;
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
}

.resultLabel {
    font-weight: bold;
    font-size: 0.9rem;
}

.containerCrop {
    display: flex;
    justify-content: center;
}

.labelCrop {
    position: absolute;
    z-index: 1;
    color: white;
    top: 1.5rem;
    text-shadow: #000 1px 0 10px;
    font-size: 16px;
    
    @include respond-below(sm) {
        font-size: 10px;
        top: 0.5rem;
    }
    
    @include respond-below(md) {
        font-size: 10px;
        top: 0.5rem;
    }
}