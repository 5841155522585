@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.iconContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    padding: 0.8rem;
    cursor: pointer;

    @include respond-below(sm) {
        padding: 0.1rem;
        width: 2.4rem;
        height: 2.4rem;
    }
}

.dropdownIcon {
    position: absolute;
    right: 0.4rem;
    bottom: 0.8rem;
    width: 0.6rem;
    height: 0.6rem;

    @include respond-below(sm) {
        right: 0.4rem;
        bottom: 0.8rem;
        width: 0.4rem;
        height: 0.4rem;
    }
}

.dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 0.6rem;
    z-index: 10;
    box-shadow: 0 1px 2px rgba(17, 17, 17, 0.55);
}
