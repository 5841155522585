@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.mainDiv {
    display: flex;
    justify-content: space-around;
    height: 500px;
    margin-top: 1rem;

    .subDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: 100%;

        @include respond-below(sm) {
            margin-bottom: 2rem;
        }
    }

    .label {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    @include respond-below(sm) {
        flex-direction: column;
        align-items: center;
    }
}

.imageColumn {
    display: flex;
    justify-content: center;
}

.image {
    max-height: 3rem;
    max-width: 6rem;
    object-fit: contain;
}

.optionsIcon {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}