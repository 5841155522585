.ql-snow {
    .ql-picker {
        &.ql-size {
            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.quill {
    .ql-editor {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0.5rem;
        }
    }
}
