@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.fileInput {
    display: none;
}

.fileWrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;

    &.disabled {
        cursor: default;
    }
}

.borderRadius{
    border-radius: 50%;
}

.noFileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background-color: color('fileInputBackground');
    // padding: 2rem 0;
    height: 100%;
}

.previewContainer {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    border: 3px solid transparent;
    padding: 3px;

    &.selectedPreview {
        border: 3px solid black;
    }

    @include respond-below(sm) {
        .previewOverlay {
            display: flex;
        }
    }

    @include respond-above(sm) {
        &:hover .previewOverlay {
            display: flex;
        }
    }
}

.previewOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.8);
    align-items: center;
    justify-content: center;
    display: none;
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
}

.text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
    color: #ACACAC;
    margin-top: 1rem;
}

.disabled {
    background-color: color('whisper');
    opacity: 1;
}

.forceAspectRatio {
    aspect-ratio: 16 / 9;
}

.deleteButton {
    background-color: #fff;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
}

.deleteButtonNotCenter{
    position: absolute;
    bottom: 0.6rem;
    right: 0.6rem;
}

.cursorPointer {
    cursor: pointer;
}

