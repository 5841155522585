@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: auto auto;
    grid-column-gap: 1.875rem;
    grid-row-gap: 2.5rem;

    @include respond-below(xl){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond-below(sm){
        grid-template-columns: 1fr 1fr;
    }

    @include respond-below(xs){
        grid-template-columns: 1fr;
    }


    .inspirationCard {
        cursor: pointer;
        .image{   
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            border-radius: 0.625rem;
            position: relative;
            aspect-ratio: 16 / 9;
        }
     

        .title {
            font-weight: 900;
            font-size: 1rem;
            line-height: 1.188rem;
            color: color("black");
            margin-top: 0.625rem;
            word-break: break-all;
        }
    }
}

.favoriteHeart{
    position: absolute;
    right: 1rem;
    top: 1rem;
}