@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.fileInput {
    display: none;
}

.fileWrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;

    &.disabled {
        cursor: default;
    }
}

.icon {
    background-color: #fff;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;

    //     padding: 0rem;
    //     width: 2.4rem;
    //     height: 2.4rem;

    @include respond-below(sm) {
        padding: 0.2rem;
        width: 2.4rem;
        height: 2.4rem;
    }
}
