@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    color: transparent;
    padding: 0;
    display: block;
    max-width: 100%;
    aspect-ratio: 16/9;
}

.cursorPointer {
    cursor: pointer;
}

.topButtons {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
}

.bottomButtons {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
}

.buttonIcon {
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin-right: 0.4rem;

    &:last-child {
        margin-right: 0;
    }

    &.buttonIconEdit {
        padding: 0rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    @include respond-below(sm) {
        padding: 0.2rem;
        width: 2.4rem;
        height: 2.4rem;
    }
}

.noImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-radius: 0.625rem;
    background-color: color('fileInputBackground');
    padding: 2rem 0;
    height: 100%;
}

.canvasImage {
    // This allows to have a dynamic size when resizing
    position: absolute;
}
