.tabsHeader{
    margin-bottom: 1rem;
}

.backIcon {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.5rem;
}

.pointer {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
}